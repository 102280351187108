'use client' // Error boundaries must be Client Components

import { useEffect } from 'react'
import ClientGrid from './grid'
import HeaderInit from './header-init'
import { reportTrackJSError } from './trackjs'

export default function Error({ error }: { error: Error & { digest?: string }; reset: () => void }) {
    useEffect(() => {
        reportTrackJSError(error)
    }, [error])

    return (
        <HeaderInit>
            <div className="mt-3 flex flex-col gap-3">
                <div className="bg-gradient-primary flex flex-col items-center justify-center rounded-lg p-5">
                    <h2>Oh-oh! Something went wrong!</h2>
                    <p>Find another GIF from our trending feed</p>
                </div>
                <ClientGrid />
            </div>
        </HeaderInit>
    )
}
